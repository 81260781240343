<template>
  <b-card>

    <b-row>

      <!-- Module Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!--  & Action Buttons -->
        <div class="d-flex justify-content-start">
          
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ moduleData.name }}
              </h4>
              
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-modules-edit', params: { id: moduleData._id } }"
                variant="primary"
              >
                Edit
              </b-button>
             
            </div>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50">
              {{ moduleData.name }}
            </td>
          </tr>
                  
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Slug</span>
            </th>
            <td class="pb-50">
              {{ moduleData.slug }}
            </td>
          </tr>

        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useModulesList from '../modules-list/useModulesList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },
//   setup() {
//     const { resolveModuleRoleVariant } = useModulesList()
//     return {
      
     
//     }
//   },
}
</script>

<style>

</style>
