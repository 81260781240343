<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="moduleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching module data
      </h4>
      <div class="alert-body">
        No module found with this module id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'modules-list'}"
        >
          Module List
        </b-link>
        for other modules.
      </div>
    </b-alert>

    <template v-if="moduleData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <module-view-module-info-card :module-data="moduleData" />
        </b-col>
      </b-row>


    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import moduleStoreModule from '../moduleStoreModule'
import ModuleViewModuleInfoCard from './ModuleViewModuleInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ModuleViewModuleInfoCard,
    

    InvoiceList,
  },
  setup() {
    const moduleData = ref(null)

    const MODULE_APP_STORE_MODULE_NAME = 'app-module'

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.unregisterModule(MODULE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-module/fetchModule', { id: router.currentRoute.params.id })
      .then(response => { 
        moduleData.value = response.data.data })
      .catch(error => {
          moduleData.value = undefined
      })

    return {
      moduleData,
    }
  },
}
</script>

<style>

</style>
